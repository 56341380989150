.Loading_Background_m{
width: 100%;
height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url('../img/intro/intro_m.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.Loading_Background_m_effect{
  width: 100%;
height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url('../img/intro/intro_m.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-animation: fade-out 1s ease-out both;
  animation: fade-out 1s ease-out both;
}

.Loading_Background_a{
  width: 100%;
height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url('../img/intro/intro_a.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.Loading_Background_a_effect{
  width: 100%;
height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url('../img/intro/intro_a.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-animation: fade-out 1s ease-out both;
  animation: fade-out 1s ease-out both;
}
.Loading_Background_n{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url('../img/intro/intro_n.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.Loading_Background_n_effect{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url('../img/intro/intro_n.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: fade-out 1s ease-out both;
  animation: fade-out 1s ease-out both;
}


.loading_bar {
    width: 90%;
    display: flex;      
    justify-content: center;
    font-size: 50px;
    color:#fff;
    -webkit-text-stroke: 1px #000;  
    padding: 50px 0 30px 0 ;
  }

.loading span {
    display: inline-block; /* span 내부요소들을 한줄로 세우기 */
    width: 5px;
    height: 5px;
    border-radius: 50%;    /* span을 동그랗게 */
    animation: loading 1s 0s linear infinite;
    margin: 0 10px ;
    /* 이벤트명  반복시간  딜레이시간  이벤트처리부드럽게  이벤트무한반복*/
  }
  
  .loading span:nth-child(1) {  /*loading의 자식 중 첫번째 span*/
    animation-delay: 0s;
    border: 1px solid #000;
    background-color: #fff;
  }
  
  .loading span:nth-child(2) {
    animation-delay: 0.2s;
    border: 1px solid #000;
    background-color: #fff;
  }
  
  .loading span:nth-child(3) {
    animation-delay: 0.4s;
    border: 1px solid #000;
    background-color: #fff;
  }

  .PhaseBackground{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }


  .Survey_box{
    width: 95%;
    height: 80%;
    display: flex;
    flex-direction: column;
    background-image: url('../img/text/if\ then_textBG.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .Survey_box_effect{
    width: 95%;
    height: 80%;
    display: flex;
    flex-direction: column;
    background-image: url('../img/text/if\ then_textBG.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-animation: fade-out 1s ease-out both;
    animation: fade-out 1s ease-out both;
  }

  .Survey_text{
    width: 90%;
    height: fit-content;
    padding: 10px 10px 50px 10px;
    margin: 5px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../img/text/if\ then_textbox.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  
  .Survey_alert{
    width: 95%;
    padding: 10px;
    font-weight: bolder;
    word-break: keep-all;
    font-size: 20px;
    color:red;
    text-align: center;
    -webkit-animation: blink-2 1.2s both infinite;
    animation: blink-2 1.2s both infinite;
  }

  .Survey_question{
    width: 95%;
    padding: 5px;
    font-weight: bolder;
    word-break: keep-all;
    line-height: 25px;
  }
  .Survey_answer{
    width: 95%;
    margin: 5px 0;
    word-break: keep-all;
    line-height: 20px;
    font-size: 13px;
    background-color: transparent;
    border: none;
    font-family: "UhBeeZZIBA-Regular";
    text-align: left;
    color: #000;
  }
  .Survey_answer:hover{
    width: 95%;
    margin: 5px 0;
    font-size: 13px;
    word-break: keep-all;
    line-height: 20px;
    text-decoration: underline;
    text-decoration-line: 20px;
    background-color: transparent;
    border: none;
    font-family: "UhBeeZZIBA-Regular";
    text-align: left;
    color: #000;
  }
  .Survey_answer:focus{
    width: 95%;
    margin: 5px 0;
    font-size: 13px;
    word-break: keep-all;
    line-height: 20px;
    text-decoration: underline;
    text-decoration-line: 20px;
    background-color: transparent;
    border: none;
    font-family: "UhBeeZZIBA-Regular";
    text-align: left;
    color: #000;
  }

  .Survey_answer_btn{
    border-radius: .8rem;
    height:fit-content;
    font-size: 15px;
    background-color: #fff;
    border: 2px solid #000;
    padding: 5px 10px;
    text-align: center;
    font-family: 'EF_jejudoldam';
    margin:  auto;
    color: #000;
  }


@-webkit-keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }


@-webkit-keyframes blink-2 {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes blink-2 {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes loading {        /*loading이라는 keyframe 애니메이션*/
    0%,                      /* 0, 50, 100은 구간*/
    100% {
      opacity: 0;            /* 안보였다가 */
      transform: scale(0.5); /*transform의 scale로 요소를 확대 또는 축소할 수 있음*/   
    }
    50% {
      opacity: 1;             /* 보였다가 */
      transform: scale(1.2); /* 1.2배 */
    }
  }