.Home_Background{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.Home_Title{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    font-size: 50px;
    margin: 20px 0;
    -webkit-animation: vibrate-1 3s linear infinite both;
    animation: vibrate-1 3s linear infinite both;
    font-family: 'EF_jejudoldam';
}

.Start_btn{
    margin: auto;
    padding: 10px 30px;
    aspect-ratio: auto 3/1;
    border: 3px solid #000;
    font-family: 'EF_jejudoldam';
    color:#000;
    font-size:30px;
    background-color: #fff;
    border-radius: 1.5rem;
}

.Login_Background{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Login_Background_effect{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-animation: fade-out 1s ease-out both;
  animation: fade-out 1s ease-out both;
}


.Login_title{
  width: 80%;
  font-size:40px;
  text-align: justify;
  text-align-last: justify;
  padding: 20px;
  font-family: 'EF_jejudoldam';
  margin: 15px auto;
}

.Login{
  width: 95%;  
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px auto;
}
.Login table{
  width: 100%;
  border: 2px solid #000;
  border-collapse : collapse;
  border-spacing : 0;
}


.Login p{
  font-size: 16px;
  font-weight: bolder;
  margin: 10px 0;
}

.Login span{
  width: 120px;
  font-size: 20px;
  line-height: 25px;
  font-weight: bolder;
  text-decoration: underline;
  text-underline-offset : 5px #000;
}

.Login_code{
  text-align: center;
  width: 120px;
  height: 25px;
  font-family:  "UhBeeZZIBA-Regular";
  font-size: 20px;
  border: none;
  font-weight: bolder;
  background-color: transparent;
}
.Loign_name_section{
  position: relative;
  text-align: center;
  width: 120px;
  height: 25px;
  font-family:  "UhBeeZZIBA-Regular";
  font-weight: bolder;
}
.Loign_name{
  position: relative;
  text-align: center;
  width: 120px;
  height: 25px;
  font-family:  "UhBeeZZIBA-Regular";
  font-size: 20px;
  border: none;
  font-weight: bolder;
  border-bottom:1px solid #000 ;
  background-color: transparent;
}
.Login_alert{
  position: absolute;
  width: 120px;
  height: 25px;
  font-family:  "UhBeeZZIBA-Regular";
  font-size: 20px;
  border: none;
  font-weight: bolder;
  -webkit-animation: blink-2 1.2s both infinite;
  animation: blink-2 1.2s both infinite;
  text-align: center;
  top:0;
}

.Login td{
  border: 2px solid #000;
}.td_img{
  height: 250px;
}
.td_subject{
  text-align: justify;
  text-align-last: justify;
  width: 50px;
  height: 30px;
  padding: 5px;
}
.td_input{
  text-align: center;
  width: 130px;
  height: 30px;
  padding: 5px;
}
.td_title{
  text-align: center;
  font-size: 20px;
  height: 30px;
}
.td_sign{
  position: relative;
  text-align: right;
  width: 100px;
  height: 30px;
  padding: 0 5px;
  font-size: 14px;
}
.td_content{
  line-height: 25px;
}

.td_start{
  text-align: center;
  width: 100%;
  height: 30px;
  padding: 5px;
}

th:first-child, td:first-child {
  border-left: none;
}



.Login select{
  text-align: center;
  width: 150px;
  height: 30px;
  color: #000;
  font-family:  "UhBeeZZIBA-Regular";
  background-color: transparent;
  font-size: 20px;
  border: none;
  
}

.Login_img{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1/.7;
}
.Login_img_accbar{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 20%;
  z-index: 1;
  position: absolute;
  top: -20%;
}

.Login_img_facebar{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 60%;
  position: relative;
}

.Login_img_acc{
  width:40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.Login_img_face{
  width:50%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Login_img_btn_right{
  width: 10%;
  aspect-ratio: 1/1;
  background-image: url('../img/btn_arrow_right.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  background-color: transparent;
}

.Login_img_btn_left{
  width: 10%;
  aspect-ratio: 1/1;
  background-image: url('../img/btn_arrow_left.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  background-color: transparent;
}


.Login_btn{
  margin: 15px  auto;
  padding: 10px 30px;
  aspect-ratio: auto 3/1;
  border: 3px solid #000;
  font-family: 'EF_jejudoldam';
  color:#000;
  font-size:30px;
  background-color: #fff;
  border-radius: 1.5rem;
}


.Login_btn1{
  position: absolute;
  word-wrap: break-word;
  width: 60px;
  aspect-ratio: 1/1;
  background-color: transparent;
  border-radius: 100%;
  right: -10px;
  font-size: 10px;
  top:-20px;
  color: red;
  font-family: 'EF_jejudoldam';
  border: 5px solid red;
  -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite both;
  animation: pulsate-fwd 0.5s ease-in-out infinite both;
}

.Login_btn2{
  position: absolute;
  word-wrap: break-word;
  width: 65px;
  aspect-ratio: 1/1;
  background-color: transparent;
  border-radius: 100%;
  right: -10px;
  font-size: 10px;
  top:-20px;
  color: red;
  font-family: 'EF_jejudoldam';
  border: 5px solid red;
  transform: rotate(-30deg)
}

.Game_Background{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
}


.Loading_Background{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.Loading_Background_effect{
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
-webkit-animation: fade-out 1s ease-out both;
animation: fade-out 1s ease-out both;
}

.loading_bar {
  width: 90%;
  display: flex;      
  justify-content: center;
  font-size: 50px;
  color:#fff;
  -webkit-text-stroke: 1px #000;  
  padding: 50px 0 30px 0 ;
}

.loading_bar_text{
  -webkit-animation: blink-2 1.2s both infinite;
  animation: blink-2 1.2s both infinite;
}


.loading span {
  display: inline-block; /* span 내부요소들을 한줄로 세우기 */
  width: 5px;
  height: 5px;
  border-radius: 50%;    /* span을 동그랗게 */
  animation: loading 1s 0s linear infinite;
  margin: 0 10px ;
  /* 이벤트명  반복시간  딜레이시간  이벤트처리부드럽게  이벤트무한반복*/
}

.loading span:nth-child(1) {  /*loading의 자식 중 첫번째 span*/
  animation-delay: 0s;
  border: 1px solid #000;
  background-color: #fff;
}

.loading span:nth-child(2) {
  animation-delay: 0.2s;
  border: 1px solid #000;
  background-color: #fff;
}

.loading span:nth-child(3) {
  animation-delay: 0.4s;
  border: 1px solid #000;
  background-color: #fff;
}



/* ----------------------------------------------
 * Generated by Animista on 2023-9-1 16:29:26
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
 @-webkit-keyframes vibrate-1 {
    0% {
      -webkit-transform: translate(0);
              transform: translate(0);
    }
    20% {
      -webkit-transform: translate(-2px, 2px);
              transform: translate(-2px, 2px);
    }
    40% {
      -webkit-transform: translate(-2px, -2px);
              transform: translate(-2px, -2px);
    }
    60% {
      -webkit-transform: translate(2px, 2px);
              transform: translate(2px, 2px);
    }
    80% {
      -webkit-transform: translate(2px, -2px);
              transform: translate(2px, -2px);
    }
    100% {
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }
  @keyframes vibrate-1 {
    0% {
      -webkit-transform: translate(0);
              transform: translate(0);
    }
    20% {
      -webkit-transform: translate(-2px, 2px);
              transform: translate(-2px, 2px);
    }
    40% {
      -webkit-transform: translate(-2px, -2px);
              transform: translate(-2px, -2px);
    }
    60% {
      -webkit-transform: translate(2px, 2px);
              transform: translate(2px, 2px);
    }
    80% {
      -webkit-transform: translate(2px, -2px);
              transform: translate(2px, -2px);
    }
    100% {
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }
  
  /* ----------------------------------------------
 * Generated by Animista on 2023-9-1 18:13:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: rotate(-30deg) scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: rotate(-30deg) scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: rotate(-30deg) scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: rotate(-30deg) scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: rotate(-30deg) scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: rotate(-30deg) scale(1);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-9-1 18:24:37
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
 @-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}


.Result{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
}

.Result table{
  width: 98%;
  border: 2px solid #000;
  border-collapse : collapse;
  border-spacing : 0;
  margin: auto;
}

.Result td{
  border: 2px solid #000;
}.td_rimg{
  width: 120px;
  height: 160px;
}
.td_rsubject{
  text-align: justify;
  text-align-last: justify;
  width: 70px;
  height: 30px;
  padding: 5px;
}
.td_result{
  width: 80px;
  height: 30px;
  padding: 5px;
  text-align: center;
}

.td_rcontent{
  padding: 10px;
  height: 50vh;
}


.td_rcontent p{
  width: 100%;
  text-align: center;
  font-size: 37px;
  margin: 30px auto;
}

.td_rcontent li{
  font-size: 20px;
  line-height: 35px;
  text-indent: -35px;
  list-style-position: inside;
}

.td_rsign{
  position: relative;
  text-align: right;
  width: 150px;
  height: 40px;
  padding: 0 15px;
}
th:first-child, td:first-child {
  border-left: none;
}

.Result_img{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 120px;
  height: 100%;
  
}

.Result_img_acc{
  position: absolute;
  width:30%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  top:-15%;
}

.Result_img_face{
  position: relative;
  width:90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.div-bg-Admin{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
}

.btn-control-Admin{
  width: 40%;
  font-size: 1.5rem;
  border-radius: .5rem;
  height:fit-content;
  background-color: #fff;
  color:#000;
  border: 2px solid #000;
  padding: 2% 2%;
  text-align: center;
  font-family: 'UhBeeZZIBA-Regular';
  margin: 15px auto;
  word-break: keep-all;
}

.block-list-Admin{
  width: 90%;
  height: 80%;
  background-color: #fff;
  color:#000;
  border: 2px solid #000;
  padding: 2% 2%;
  margin: auto;
  text-align: center;
  font-size: 1.5rem;
}

@font-face {
  font-family: 'KBO-Dia-Gothic_bold';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2304-2@1.0/KBO-Dia-Gothic_bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

.block-bg{
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.block-bg-print{
  width: 1280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'KBO-Dia-Gothic_bold';
}

.block-title-print{
  width: 1000px;
  height: 150px;
  font-size: 100px;
  text-align: justify;
  text-align-last: justify;
  font-weight: 900;  
  font-family: 'KBO-Dia-Gothic_bold';
  margin: 50px 0;
}

.block-header-print{
  width: 1280px;
  height: 500px;
  display: flex;
  border: 3px solid #000;
}

.block-header-print-textbox{
  width: 800px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.block-header-print-textbox-01{
  width: 790px;
  padding: 5px ; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 100px;
  font-weight: 900;
  margin: auto;
}


.block-header-print-textbox-02{
  width: 790px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  margin: auto;
}
.block-header-print-textbox-03{
  width: 790px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  margin: auto;
}


.block-header-print-imgbox{
  width: 475px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-right: 3px solid #000;
}

.block-header-print-imgbox_acc{
  position: absolute;
  width:20%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  top:-10%;
}

.block-header-print-imgbox_face{
  position: relative;
  width:90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.block-content-print{
  width: 1280px;
  border: 3px solid #000;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.block-content-print-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  text-indent: 20px;
}

.block-content-print-title{
  background-color: #e1e1e1;
  padding: 10px;
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-indent: 0px;
}

.block-content-print-content{
  padding: 30px;
  display: flex;
  flex-direction: column;
  word-break:break-all
}

.block-content-print-index{
  padding: 10px;
  font-size: 35px;
  display: flex;
  align-items: center;
  background-color: #e1e1e1;
}

.block-content-print-index-subject{
  width: 250px;
  text-align: justify;
  text-align-last: justify;
}

.block-header-print-textbox-icon{
  font-size: 30px;
  margin: 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.block-content-print-indexEx{
  padding: 20px;
  font-size: 23px;
  display: flex;
  word-break:break-all
}

.block-content-print-index-bar{
  position: relative;
  width: 250px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../img/game/game_time.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 0 5px  0 15px ;
  }
  
  .block-content-print-index-color_bar{
  border-radius: 10rem;
  position: absolute;
  z-index: 1;
  left: 0;
  height: 99%;
  background-image: url('../img/game/game_time_bar.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%
  }
  
  .block-content-print-index-star{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'UhBeeZZIBA-Regular';
    font-size: 40px;
  }

  .block-content-print-instar{
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: space-around;

  }

  .block-content-print-instarpeed{
    width:400px;
    height: 100%;
    border: 2px solid #000;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
  }

  .block-content-print-instarpeed-img{
    width: 390px;
    height: 390px;
    
    margin: auto;
  }

  .block-content-print-instarpeed-tag{
    width: 390px;
    margin: auto;
    font-size: 30px;
    word-break:break-all;
    text-indent: 0px;
  }

  .block-content-print-instarpeed-state{
    width: 390px;
    height: 40px;
    margin: auto;
    display: flex;
    align-items: center;
    font-size: 35px;
  }
