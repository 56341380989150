@font-face {
  font-family: 'EF_jejudoldam';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2210-EF@1.0/EF_jejudoldam.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'UhBeeZZIBA-Regular';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2104@1.0/UhBeeZZIBA-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "UhBeeZZIBA-Regular";
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
  margin: 0;
  position:fixed;
  display: flex;
  overflow:hidden;
}