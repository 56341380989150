.wrapper_iPhone {
  width: 100vmin;
  height: 85vmax;
  display: flex;
  flex-direction: column;
  background-image: url('./img/if\ then_BG.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}

.wrapper_Android {
  width: 100vmin;
  height: 93vmax;
  display: flex;
  flex-direction: column;
  
  align-items: center;
  background-image: url('./img/if\ then_BG.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}


.wrapper{
  background-image: url('./img/if\ then_BG.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}