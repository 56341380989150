.Popup_back{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
}

.Popup_alert{
    position: fixed;
    top:30%;
    width: 300px;
    aspect-ratio: 1/.5;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: .8rem;
    border: 3px solid #000;
}


.Popup_explanation{
    position: fixed;
    top:7%;
    width: 300px;
    height: 550px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: .8rem;
    border: 3px solid #000;
    padding: 10px;
}

.Popup_explanation_title{
    width: 90%;
    height: fit-content;
    font-size: 40px;
    text-align: center;
    margin: auto;
    color:#000;
}

.Popup_explanation_content{
    width: 85%;
    aspect-ratio: 1/1.65;
    /* background-color: #fff;
    border-radius: .8rem;
    border: 3px solid #000; */
}

.Popup_alert_text{
    width: 90%;
    height: fit-content;
    font-size: 20px;
    text-align: center;
    margin: auto;
    word-break: keep-all;
    color:#000;
}

.Popup_alert input{
    text-align: center;
    width: 120px;
    height: 25px;
    font-family:  "UhBeeZZIBA-Regular";
    font-size: 20px;
    border: none;
    font-weight: bolder;
    background-color: transparent;
}

.Popup_result{
    position: fixed;
    top:15%;
    width: 250px;
    height: 300px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: .8rem;
    border: 3px solid #000;
    padding: 10px;
}

.Popup_result_title{
    width: 90%;
    height: fit-content;
    font-size: 40px;
    text-align: center;
    color:#000;
}

.Popup_result_content{
    width: 90%;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
}
.Popup_result_line{
    width: 90%;
    height: 20%;
    margin: auto;
    display: flex;
    align-items: center;
    
  }

.Popup_result_subject{
    text-align: justify;
    text-align-last: justify;
    width:100px;
    height: 25px;
    padding-top: 5px;
    
  }

  .Popup_result_text{
    width: fit-content;
    height: 25px;
    padding-top: 5px;
    margin-left: 15px;
    align-items: center;
    justify-content: center;
    
  }


.Popup_btn{
    width: 40%;
    border-radius: .5rem;
    height:fit-content;
    font-size: 20px;
    background-color: #fff;
    color:#000;
    border: 2px solid #000;
    padding: 2% 2%;
    text-align: center;
    font-family: 'EF_jejudoldam';
    margin: auto;
    word-break: keep-all;
}

.Popup_btn2{
    width: fit-content;
    border-radius: .5rem;
    height:fit-content;
    font-size: 20px;
    background-color: #fff;
    border: 2px solid #000;
    padding: 2% 2%;
    text-align: center;
    font-family: 'EF_jejudoldam';
    font-weight: bolder;
    margin: auto;
    word-break: keep-all;
}