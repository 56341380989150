.Info{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-bottom: 4px solid #000;
    z-index: 0;
  }

  .Info_img{
    width: 30%;
    aspect-ratio: 1/1;
    background-color: #fff;
    border-left: 4px solid #000;
    border-right: 4px solid #000;
    border-top: 4px solid #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    
  }
  .Info_img_acc{
    width:50%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -10%;
    
  }

  .Info_img_face{
    width:80%;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .Info_data{
    width: 60%;
    height: 90%;
    display: flex;
    flex-direction: column;
    
  }

  .Info_data_line{
    width: 90%;
    height: 20%;
    padding: 2.5px 0 ;
    margin: auto;
    display: flex;
    align-items: center;

  }

  .Info_data_subject{
    text-align: justify;
    text-align-last: justify;
    width: 70px;
    height: 25px;
    padding-top: 5px;
  }

  .Info_data_text{
    width: 120px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Info_data_index_bar{
    width:100px;
    height: 10px;
    border-radius: 100px;
    border: 1px solid #000;
    position: relative;
  }

  .Info_data_index_color_bar{
    height: 10px;
    border-radius: 100px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  .Info_data_index_num{
    position: absolute;
    width:100px;
    height: 10px;
    border-radius: 100px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    font-size: 13px;
  }
  .Game_avoid_launch_not{
    width: 100%;
    height: 100%;
    transition: 0.3s;
  }

  .Game_avoid_launch_missile{
    width: 100%;
    height: 100%;
    background-image: url('../img/game/game_p_v_m.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: .5s;
  }
  .Categorize_object1{
    width: 100%;
    height: 100%;
    background-image: url('../img/game/game_b_gbox1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Categorize_object2{
    width: 100%;
    height: 100%;
    background-image: url('../img/game/game_b_gbox2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Categorize_object3{
    width: 100%;
    height: 100%;
    background-image: url('../img/game/game_b_wbox1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Categorize_object4{
    width: 100%;
    height: 100%;
    background-image: url('../img/game/game_b_wbox2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .Card1{
    width: 100%;
    height: 100%;
    background-image: url('../img/game/game_w_card1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Card2{
    width: 100%;
    height: 100%;
    background-image: url('../img/game/game_w_card2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Card3{
    width: 100%;
    height: 100%;
    background-image: url('../img/game/game_w_card3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Card4{
    width: 100%;
    height: 100%;
    background-image: url('../img/game/game_w_card4.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Card5{
    width: 100%;
    height: 100%;
    background-image: url('../img/game/game_w_card5.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Card6{
    width: 100%;
    height: 100%;
    background-image: url('../img/game/game_w_card6.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Card7{
    width: 100%;
    height: 100%;
    background-image: url('../img/game/game_w_card7.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Card8{
    width: 100%;
    height: 100%;
    background-image: url('../img/game/game_w_card8.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }


  .Game_ready{
    z-index: 3;
    position: fixed;
    top: 35%;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Game_time{
    width: 80%;
    height: fit-content;
    font-size: 40px;
    color:#fff;
    text-align: center;
    -webkit-text-stroke: 1px #000;
    text-shadow:  0 0 10px  #000;
    margin: 30px 0 15px 20px ;
    z-index: 2;
  }
  
  .Game_timebar{
  position: relative;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../img/game/game_time.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  }
  
  .Game_timecolor_bar{
  border-radius: 10rem;
  position: absolute;
  z-index: 3;
  left: 0;
  height: 90%;
  background-image: url('../img/game/game_time_bar.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%
  }
  
  .Game_timer{
  position: absolute;
  z-index: 4;
  top: -50%;
  left: -5%;
  width: 40px;
  aspect-ratio: 1/1;
  background-image: url('../img/game/game_timer.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  }

  .Face_w0{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/face_w1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Face_w1{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/face_w2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Face_w2{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/face_w3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Face_w3{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/face_w4.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .Face_b0{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/face_b1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Face_b1{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/face_b2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Face_b2{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/face_b3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Face_b3{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/face_b4.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .Face_p0{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/face_p1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Face_p1{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/face_p2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Face_p2{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/face_p3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Face_p3{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/face_p4.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .Acc_p0{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/acc_p1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Acc_p1{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/acc_p2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Acc_p2{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/acc_p3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Acc_p3{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/acc_p4.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .Acc_b0{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/acc_b1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Acc_b1{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/acc_b2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Acc_b2{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/acc_b3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Acc_b3{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/acc_b4.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .Acc_w0{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/acc_w1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Acc_w1{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/acc_w2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Acc_w2{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/acc_w3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Acc_w3{
    width: 100%;
    height: 100%;
    background-image: url('../img/chac/acc_w4.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .ex_b1{
    width: 100%;
    height: 100%;
    background-image: url('../img/ex/game_ex_b_1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .ex_b2{
    width: 100%;
    height: 100%;
    background-image: url('../img/ex/game_ex_b_2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .ex_w1{
    width: 100%;
    height: 100%;
    background-image: url('../img/ex/game_ex_w_1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .ex_w2{
    width: 100%;
    height: 100%;
    background-image: url('../img/ex/game_ex_w_2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .ex_p1{
    width: 100%;
    height: 100%;
    background-image: url('../img/ex/game_ex_p_1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .ex_p2{
    width: 100%;
    height: 100%;
    background-image: url('../img/ex/game_ex_p_2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .endimg-01{
    width: 100%;
    height: 100%;
    background-image: url('../img/end/ending_elevator.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .endimg-02{
    width: 100%;
    height: 100%;
    background-image: url('../img/end/ending_Help.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .endimg-03{
    width: 100%;
    height: 100%;
    background-image: url('../img/end/ending_bed.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .endimg-04{
    width: 100%;
    height: 100%;
    background-image: url('../img/end/ending_lunch.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .endimg-05{
    width: 100%;
    height: 100%;
    background-image: url('../img/end/ending2_gone.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .endimg-06{
    width: 100%;
    height: 100%;
    background-image: url('../img/end/ending_coffee.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .endimg-07{
    width: 100%;
    height: 100%;
    background-image: url('../img/end/ending_work.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .endimg-08{
    width: 100%;
    height: 100%;
    background-image: url('../img/end/ending_clock.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .endimg-09{
    width: 100%;
    height: 100%;
    background-image: url('../img/end/ending_mountain.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }