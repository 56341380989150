.PhaseBackground{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.Game{
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.Gamew2{
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-image: url('../img/game/game_w_2.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}


.Game_colum_background{
  width: 100%;
  height: 70%;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  position: relative;
}

.Paper_txt{
  width: 80%;
  height: fit-content;
  padding: 15px 10px 5px 10px ;
  margin: 10px 0 0 0 ;
  text-align: left;
  background-image: url('../img/game/game_w_1.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-size: 13px;
}
h2{
  font-size: 16px;
  margin: 5px 0;
}
ul{
  margin: 0;
}
li{
  margin: 0;
}

.Game_btn_box{
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;  
}
.Game_btn_controller{
  width: 95%;
  aspect-ratio: 1/.25;
  display: grid;
  margin: 15px auto;
  grid-template-columns: repeat(4,1fr);
  padding: 1%;
  gap: 1%;
}
.Game_btn{
  z-index:1;
  width: 70%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5em;
  background-color: #fff;
  border: 2px solid #000;
  box-shadow: 5px 5px 1px #000;
  transition-duration: 0.3s;
  margin: auto;
}
.Game_btn:active{
  margin-left: 15px;
  margin-top: 15px;
  box-shadow: none;
}
.Game_arrow_Lbtn{
  width: 100%;
  aspect-ratio: 1/.8;
  background-image: url('../img/btn_arrow_left.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  background-color: transparent;
}
.Game_arrow_Rbtn{
  width: 100%;
  aspect-ratio: 1/.8;
  background-image: url('../img/btn_arrow_right.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  background-color: transparent;
}

.Typing_display_Box{
  width:95%;
  height: 55%;
  display:flex;
  flex-direction: column;
  align-items: center;
  margin:  auto;
  background-image: url('../img/game/game_w2_box.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  
}



.Typing_display{
  width:75%;
  height: 30%;
  font-size: 80px;
  display: flex;
  align-items: center;
  margin-top: 10%;
  grid-template-columns: repeat(4,1fr);
}

.Typing_display_insideColor{
  height:100%;
  width:100%;
  background-color: skyblue;
  border-radius: 20%;
  display:flex;
  align-items: center;
  justify-content: center;
}

.Typing_display_inside{
  height:100%;
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;
}

.Typing_display_wrong{
  width:75%;
  height: 30%;
  font-size: 80px;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-top: 13%;
  color:red
}

.Categorize_display_alert{
font-size: 20px;
margin: 5px auto;
}

.Categorize_display_Box{
  height:100%;
  width:50%;
  display: grid;
  grid-template-rows: repeat(4,1fr);
  gap: 1%;
  
  background-image: url('../img/game/game_b_1.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90% 100%;

}

.Categorize_object{
  width: 40%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Categorize_display_Red{
  height:100%;
  width:50%;
  display: grid;
  grid-template-rows: repeat(4,1fr);
  gap: 1%;
  padding-top:15% ;
  background-image: url('../img/game/game_b_1.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90% 100%;
  background-color: red;
}
.Game_Part{
  width: 80%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.Game_Part1{
  z-index: 0;
  position: absolute;
  margin-top: 70px;
  width: 150px;
  height: 200px;
  background-image: url('../img/game/game_b_part1.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.Game_Part2{
  z-index: 0;
  position: absolute;
  width: 150px;
  height: 70px;
  background-image: url('../img/game/game_b_part2.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: -200px;
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.Game_Part3{
  z-index: 0;
  position: absolute;
  width: 70px;
  height: 150px;
  background-image: url('../img/game/game_b_part3.png');
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 20px;
  margin-left: -220px;
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.Game_Part4{
  z-index: 0;
  position: absolute;
  width: 70px;
  height: 150px;
  background-image: url('../img/game/game_b_part4.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 20px;
  margin-right: -220px;
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.Game_Part_finish{
  z-index: 1;
  position: absolute;
  width: 80%;
  height: fit-content;
  font-size: 40px;
  color:#fff;
  text-align: center;
  -webkit-text-stroke: 1px #000;
  text-shadow:  0 0 10px  #000;
}

.Game_avoid_launch{
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: space-around;
}

.Game_avoid_launch_line{
  z-index: 1;
  position:absolute;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(6,1fr);

}

.Game_avoid_character_line{
  position: relative;
  width: 33%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(6,1fr);
}

.Game_avoid_character{
  width: 100%;
  height: 16%;
  background-image: url('../img/game/game_p_ch.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  z-index: 3;
  bottom: 0;
}

.Game_avoid_characterA{
  width: 100%;
  height: 16%;
  background-image: url('../img/game/game_p_chx.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  z-index: 3;
  bottom: 0;
  -webkit-animation: vibrate-3 0.5s linear infinite both;
  animation: vibrate-3 0.5s linear infinite both;
}

.Game_avoid_v1{
  width: 100%;
  height: 100%;
  background-image: url('../img/game/game_p_v1.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.Game_avoid_v2{
  width: 100%;
  height: 100%;
  background-image: url('../img/game/game_p_v2.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.Game_avoid_v3{
  width: 100%;
  height: 100%;
  background-image: url('../img/game/game_p_v3.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}


.Game_card{
  width: 80%;
  aspect-ratio: 1/1.3;
  display: grid;
  margin: 5px auto;
  grid-template-columns: repeat(4,1fr);
  grid-template-rows: repeat(4,1fr);
  padding: 1%;
  gap: 1%;
}
.Game_cards{
  width: 99%;
  height: 99%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5em;
  position: relative;
  transition: 1s;
  transform: rotateY(0deg);
  transform-style: preserve-3d;
}

.card-side-front {
  width: 99%;
  height: 99%;
  background-image: url('../img/game/game_p_cardback.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.card-side-back {
  width: 99%;
  height: 99%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: flip-vertical-left 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
  animation: flip-vertical-left 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

@-webkit-keyframes flip-vertical-left {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  50% {
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
  }
  100% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
    }
}
@keyframes flip-vertical-left {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  50% {
      -webkit-transform: rotateY(-180deg);
              transform: rotateY(-180deg);
    }
    100% {
        -webkit-transform: rotateY(0);
                transform: rotateY(0);
      }
}

 @-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}


 @-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

 @-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

 @-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-9-14 13:52:19
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-3
 * ----------------------------------------
 */
 @-webkit-keyframes vibrate-3 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  10% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  20% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  30% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  50% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  70% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  80% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  90% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@keyframes vibrate-3 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  10% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  20% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  30% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  50% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  70% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  80% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  90% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
